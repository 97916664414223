import React from 'react';
import { useStyletron } from 'baseui';
import { Badge, SHAPE } from 'baseui/badge';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarWithFallbackOnError } from '@gtintel/platform-atoms';
import { DEFAULT_AVATAR } from '@gtintel/platform-constants';
import { COLOURS } from '@gtintel/platform-utils';
const UserIcon = ({
  user,
  showName,
  tagLine,
  empty_message = 'No partner assigned'
}) => {
  const [css] = useStyletron();
  const avatar = user?.avatar ? user.avatar : DEFAULT_AVATAR;
  const title = user ? user.full_name ?? user.email : empty_message;
  return <div className={css({
    alignItems: 'center',
    display: 'flex',
    gap: '8px'
  })} title={title} data-testid="user-icon">
      <div className={css({
      maxWidth: '32px',
      borderRadius: '16px'
    })}>
        <AvatarWithFallbackOnError imageSrc={avatar} fallback={DEFAULT_AVATAR} alt={title} />
      </div>
      <div>
        {showName && <p className={css({
        fontWeight: 'bold',
        margin: '0'
      })}>{title}</p>}
        {tagLine && <p className={css({
        fontSize: '.8em',
        lineHeight: '1em',
        margin: '0',
        paddingRight: '4px'
      })}>
            {tagLine}
          </p>}
      </div>
    </div>;
};
export const UserPill = ({
  user,
  title,
  showName,
  tagLine,
  suffixElement,
  overlap,
  hasBorder,
  borderColor,
  background,
  empty_message
}) => {
  const [css, theme] = useStyletron();
  let border = '0';
  let marginLeft = '0';
  const padding = '8px 12px';
  if (overlap || hasBorder) {
    border = `1px solid ${borderColor || '#e2e2e2'}`;
  }
  if (overlap) {
    marginLeft = suffixElement ? '-28px' : '-14px';
  }
  return <div className={css({
    alignItems: 'center',
    background: background ?? theme.colors.backgroundPrimary,
    border,
    borderRadius: '28px',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '8px',
    marginLeft,
    padding,
    width: 'fit-content'
  })} title={title} data-testid="user-pill">
      <UserIcon user={user} showName={showName} empty_message={empty_message} tagLine={tagLine} />
      {suffixElement}
    </div>;
};
export const SelectableUserPill = ({
  selected,
  onClick,
  user,
  title,
  showName,
  tagLine,
  suffixElement,
  overlap,
  hasBorder,
  background,
  empty_message
}) => {
  const [css] = useStyletron();
  const badge = selected ? <FontAwesomeIcon icon={faCheck} color={COLOURS.white} /> : null;
  const borderColor = selected ? COLOURS.blue : 'transparent';
  const pill = <UserPill user={user} title={title} showName={showName} tagLine={tagLine} suffixElement={suffixElement} overlap={overlap} hasBorder={false} background={background} empty_message={empty_message} />;
  return <div data-testid="selectable-user-pill" onClick={() => onClick(user)} className={css({
    border: `2px solid ${borderColor}`,
    borderRadius: '28px',
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 250ms linear',
    width: 'fit-content',
    ':hover': {
      borderColor: COLOURS.blue,
      transition: 'all 250ms linear'
    }
  })}>
      {selected ? <Badge content={badge} shape={SHAPE.pill} verticalOffset="-6px" horizontalOffset="-6px">
          {pill}
        </Badge> : pill}
    </div>;
};
export default UserIcon;